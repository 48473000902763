import React from "react";
import {graphql} from "gatsby";
import CategoryLayout from "../../components/tronpedia/category-layout";
import {Helmet} from "react-helmet";
import nftBull from "../../images/tronpedia/topic-pages/nft-bull.png";

const Nft = ({data, location}) => {

    const pageData = {
        title: "NFT",
        description: "Non fungi-what!? Here, we break down NFTs and discuss what they are, their potential to revolutionize industries, and some of the most-hyped projects.",
        img: nftBull,
    }


    return (
        <>
            <Helmet>
                <title>TRONpedia NFT | TRONDAO</title>
                <meta name="description"
                      content="Discover what NFTs are, what all  the surrounding hype around them is about, why they're so revolutionary, how you can get involved and so much more."/>
                <meta property="og:description"
                      content="Discover what NFTs are, what all  the surrounding hype around them is about, why they're so revolutionary, how you can get involved and so much more."/>
                <meta property="og:title" content="TRONpedia NFT | TRONDAO"/>
            </Helmet>
            <CategoryLayout data={data} location={location} pageData={pageData}/>
        </>
    );
};


export default Nft;

export const pageQuery = graphql`
query {
   allWpTronpediaArticle (
    filter: {tronpedia_article_info: {category: {eq: "NFT"}}}
  ) {  
      edges {
         node {
            slug
        title
        tronpedia_article_info {
           category
          featured
          seoDescription
          seoTitle
          shortDescription
          image {
                  sourceUrl
               }
            }
         }
      }
   }
}

`
